<template>
  <div class="submitErr">
    <div class="main">
      <p class="tips-text">您有必填信息未填，请补充完整后再提交</p>
      <ul class="list-box">
        <li
          @click="backIndex(item, index)"
          :class="[item.state === 0 ? 'err' : '']"
          v-for="(item, index) in errList"
          :key="index"
        >
          <p>
            <span class="success-icon iconfont icon-yitian"></span
            ><span class="err-icon iconfont icon-weitian"></span>
            {{ item.templateMenuDesc }}
          </p>
        </li>
      </ul>
    </div>
    <div class="footer">
      <van-button type="info" @click="back">返回</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import untis from "../../utils";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("user", {
      errList: "errList",
    }),
  },
  created() {
    // console.log(this.errList);
  },
  methods: {
    back() {
      this.$router.back(-1);
      // this.$router.push({ path: "/user", query: { ...this.$route.query } });
    },
    backIndex(item, index) {
      if (item.state === 0) {
        // this.$router.push({ path: "/user", query: { ...this.$route.query } });
        // untis.setSessionStorage("backData", item.templateMenuDesc);
        //跳到对应的详情
        this.$router.replace({
          path: item.path,
          query: {
            ...this.$route.query, //模版ID和clientCode
            id: item.id,
            title: item.text,
            menuLayout: item.menuLayout, //可以添加类型的个数
            astatus: item.approvalStatus, //审核状态
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.submitErr {
  padding-top: 10px;
  font-size: 15px;
  .main {
    width: 345px;
    background: #ffffff;
    border-radius: 6px;
    margin: 0 auto;
    padding: 30px 12px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-bottom: 30px;
    .tips-text {
      width: 100%;
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e8534c;
    }
    .list-box {
      width: 100%;
      & > li {
        width: 100%;
        border-bottom: 1px solid #e8e8e8;
        padding: 16px 33px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        p {
          color: #bfbfbf;
          span {
            margin-right: 38px;
            color: #48b07a;
          }
          .err-icon {
            display: none;
          }
        }
        &.err {
          p {
            color: #141614;
          }
          span {
            color: #e8534c;
          }
          .success-icon {
            display: none;
          }
          .err-icon {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>